import React from 'react';
import {
  Grid,
  FormLabel,
  TextField,
  Divider,
} from '@mui/material';
import theme from '../../utils/theme';

const ContactInfo = ({
  classes,
  error,
  TEXT_FIELDS,
}) => {
  return (
    <Grid container item flexDirection='column' justifyContent='center' alignItems='center' className={classes.formPageContainer}>
        <FormLabel>Contact Information</FormLabel>
            {TEXT_FIELDS.map(field => (
                <Grid key={`text-field-${field.label}`} item sx={{padding: [theme.palette.spacing.mobile.md, theme.palette.spacing.md]}}>
                    <TextField
                        sx={{width: '300px'}}
                        value={field.value}
                        label={field.label}
                        variant="outlined"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!(error && field.value === '')}
                    />
                </Grid>
            ))}
        <Divider />
    </Grid>
  );
}

export default ContactInfo;
