import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Box,
    Button,
    Grid,
    FormLabel,
    MobileStepper,
    Modal,
    Paper,
    Typography,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import NumberInputBasic from './NumberInput';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { baseColors } from '../../utils/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.palette.spacing.sm,
  },
  groupContainer: {
    [theme.breakpoints.up("md")]: {
        padding: theme.palette.spacing.sm,
    },
    [theme.breakpoints.down("md")]: {
        padding: `${theme.palette.spacing.mobile.sm} ${theme.palette.spacing.mobile.xs}`,
    },
  },
  priceLabel: {
    fontSize: '.75rem',
    fontWeight: 'bold',
    color: theme.palette.baseColors.darkgrey,
    paddingBottom: '3px',
    position: 'relative',
    top: '-15px',
  },
  qtyLabel: {
    fontSize: '.75rem',
    fontWeight: 'bold',
    color: theme.palette.baseColors.darkgrey,
    paddingBottom: '2px',
    position: 'relative',
    top: '-15px',
  },
}));

const BoardSelect = ({group}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  
  const handleOpen = (idx) => {
    setActiveStep(idx);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const maxSteps = group.items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Grid container flexDirection='column' className={classes.root}>       
        {group.items.map((item, idx) => (
            <Grid className={classes.groupContainer} key={`sel-col-type-${item.label}`} container item flexDirection='row' justifyContent="space-between">
                <Grid item display='flex' alignItems='center' xs={6} sm={8.8} md={7} sx={{flexDirection: ['column', 'row']}}>
                    <img onClick={() => handleOpen(idx)} src={item.pic} alt={item.label} width={`${item.width}px`} height={`${item.height}px`} style={item.style} />
                    <Grid item display='flex' flexDirection='column'>
                        <FormLabel sx={{fontSize: '1rem', textAlign: ['center', 'start']}}>{item.label}</FormLabel>
                        <Typography variant='caption' sx={{textAlign: ['center', 'start'], textWrap: 'balance'}}>{item.desc}</Typography>
                    </Grid>
                </Grid>
                <Grid item display='flex' flexDirection='column' justifyContent={item.priceLabel ? 'flex-start' : 'space-around'}>
                    {item.priceLabel &&
                        <Typography className={classes.priceLabel}>
                            {item.priceLabel}
                        </Typography>}
                    <Typography variant='h6'>{`$${item.price}`}</Typography>
                </Grid>
                <Grid item display='flex' flexDirection='column' justifyContent={item.qtyLabel ? 'flex-start' : 'space-around'}>
                    {item.qtyLabel &&
                        <Typography className={classes.qtyLabel}>
                            {item.qtyLabel}
                        </Typography>}
                    <NumberInputBasic
                        value={item.value}
                        onChange={(e, val) => item.onChange(parseInt(val))}
                    />
                </Grid>
            </Grid>
        ))}
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={{
                position: 'absolute',
                [theme.breakpoints.up("md")]: {
                    width: '40%',
                    right: '30%',
                },
                [theme.breakpoints.up("sm")]: {
                    width: '80%',
                    right: '10%',
                },
                [theme.breakpoints.down("sm")]: {
                    width: '100%',
                },
                top: '20%',
            }}>
                <Paper
                    square
                    elevation={0}
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                    }}
                >
                    <FormLabel>{group.items[activeStep].label}</FormLabel>
                </Paper>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {group.items.map((step, index) => (
                    <div key={step.label} style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}>
                        {Math.abs(activeStep - index) <= 3 ? (
                        <Box
                            component="img"
                            sx={{
                            height: (step.height * 4),
                            width: (step.width * 4),
                            display: 'block',
                            overflow: 'hidden',
                            }}
                            src={step.pic}
                            alt={step.label}
                        />
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                        '& .MuiMobileStepper-dots': {
                            '& .MuiMobileStepper-dotActive': {
                                backgroundColor: baseColors.yellow,
                            },
                        },
                    }}
                    nextButton={
                    <Button
                        color="secondary" 
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                        ) : (
                        <KeyboardArrowRight />
                        )}
                    </Button>
                    }
                    backButton={
                    <Button color="secondary" size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                        ) : (
                        <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                    }
                />
            </Box>
        </Modal>
    </Grid>
  );
}

export default BoardSelect;