import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormLabel,
  Typography,
  Divider,
 } from '@mui/material';
import theme from '../../utils/theme';
import { calculateTotal, formatDay, spaceOrNewLine } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
    container: {
      padding: theme.palette.spacing.sm,
    },
    divider: {
      borderBottomWidth: '2px',
      width: '100%', 
    },
    formPageContainer: {
        [theme.breakpoints.up("sm")]: {
            padding: theme.palette.spacing.lg,
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.palette.spacing.mobile.lg,
        },
    },
    formLabel: {
      padding: theme.palette.spacing.xs,
      width: '100%',
    },
    text: {
        [theme.breakpoints.up("sm")]: {
            padding: theme.palette.spacing.xs,
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.palette.spacing.mobile.xs,
        },
        wordWrap: 'normal',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > span': {
            fontWeight: 'bold',
        },
        color: theme.palette.baseColors.darkgrey,
        width: '100%',
        textAlign: 'start',
    },
}));

const Details = ({
  form,
  name,
  number,
  email,
  date,
  PICKUP_OPTS,
  pickup,
  comments,
  BOARDS,
  boardAQuantity,
  boardBQuantity,
  boardCQuantity,
}) => {
  const classes = useStyles();

  const separator = useMemo(() => spaceOrNewLine(), []);

  return (
    <Grid
        ref={form}
        container
        item
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        alignSelf='center'
        className={classes.formPageContainer}
        xs={11}
    >
        <FormLabel className={classes.formLabel}>Order Details</FormLabel>
        <Grid className={classes.container} container flexDirection='column' alignItems='flex-start'>
            <Typography className={classes.text} variant='body1'>
                <Typography component={`span`} variant='body1'>Name:</Typography>{separator}{name}
            </Typography>
            <Typography className={classes.text} variant='body1'>
                <Typography component={`span`} variant='body1'>Phone Number:</Typography>{separator}{number}
            </Typography>
            <Typography className={classes.text} variant='body1'>
                <Typography component={`span`} variant='body1'>Email:</Typography>{separator}{email}
            </Typography>
            <Typography className={classes.text} variant='body1'>
                <Typography component={`span`} variant='body1'>Scheduled Pick Up:</Typography>
                {separator}{`${date.toLocaleString('en-us', {weekday:'long'})}, ${formatDay(date.toLocaleString('en-us', {month:'long', day: '2-digit'}))} @ ${PICKUP_OPTS.items[pickup].location}
                `}
            </Typography>
            <Typography className={classes.text} variant='body1'>
                <Typography component={`span`} variant='body1'>Additional comments:</Typography>{separator}{comments}
            </Typography>
        </Grid>
        <Grid className={classes.container} container flexDirection='column' alignItems='flex-start'>
            <Grid container flexDirection='row'>
                <Grid item xs={5}>
                    <Typography className={classes.text} variant='body1' sx={{fontWeight: 'bold', textAlign: 'start'}}>Items</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.text} variant='body1' sx={{fontWeight: 'bold', textAlign: 'center'}}>Quantity</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.text} variant='body1' sx={{fontWeight: 'bold', textAlign: 'center'}}>Amount</Typography>
                </Grid>
            </Grid>
            <Divider
              className={classes.divider}
              style={{ padding: theme.palette.spacing.xs, borderColor: theme.palette.baseColors.lightgrey }}
            />
            {BOARDS.items.filter(board => board.value !== 0).map(board => (
                <Grid key={`board-summary-${board.label}`} container flexDirection='row' sx={{padding: `${theme.palette.spacing.sm} 0`}}>
                    <Grid item xs={5}>
                        <Typography className={classes.text} variant='body1' sx={{textAlign: 'start'}}>{`${board.label} ($${board.price})`}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.text} variant='body1' sx={{textAlign: 'center'}}>{board.value}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.text} variant='body1' sx={{textAlign: 'center'}}>${board.value * board.price}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Divider
              className={classes.divider}
              style={{ padding: theme.palette.spacing.sm, borderColor: theme.palette.baseColors.darkgrey }}
            />
            <Grid container flexDirection='row' sx={{padding: `${theme.palette.spacing.sm} 0`}}>
                <Grid item xs={5}>
                    <Typography className={classes.text} variant='body1' sx={{fontWeight: 'bold', textAlign: 'start'}}>Total Amount</Typography>
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={3}>
                    <Typography className={classes.text} variant='body1' sx={{fontWeight: 'bold', textAlign: 'center'}}>${calculateTotal({boardAQuantity, boardBQuantity, boardCQuantity})}</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Divider />
        <Grid container justifyContent='center'>
            <Typography variant='overline' style={{color: 'red', fontStyle: 'italic', fontWeight: 'bold'}}>Payments must be made via Venmo or Zelle within 24 hours.</Typography>
        </Grid>
    </Grid>
  );
}

export default Details;
