import React from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker, { CalendarContainer } from "react-datepicker";
import {
    Grid,
    FormLabel,
    Typography,
    Divider,
    TextField,
} from '@mui/material';
import { isMobile, isValidDate } from '../../utils/helpers';
import theme from '../../utils/theme';
import LocationSelect from './LocationSelect';
import BoardSelect from './BoardSelect';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    boxSizing: 'border-box',
    '& > .MuiFormLabel-root': {
      color: theme.palette.baseColors.gray,
    },
    [theme.breakpoints.up("md")]: {
        padding: `${theme.palette.spacing.sm} !important`,
    },
    [theme.breakpoints.down("md")]: {
        padding: `${theme.palette.spacing.mobile.sm} ${theme.palette.spacing.mobile.xs} !important`,
    },
  },
  calendarRoot: {
    width: '300px',
    borderRadius: '21px',
    background: theme.palette.baseColors.white,
    fontFamily: "Segoe UI",
    fontSize: "0.8rem",
    backgroundColor: theme.palette.baseColors.white,
    color: theme.palette.baseColors.black,
    border: `2px solid ${theme.palette.baseColors.darkgrey}`,
    borderRadius: "21px",
    display: "inline-block",
    position:" relative",
    lineHeight:" initial",
    "& > *": {
        "& > .react-datepicker__navigation--next": {
            color: theme.palette.baseColors.brown,
        },
        "& > .react-datepicker__month-container": {
            width: '300px',
            backgroundColor: 'transparent',
            "& > .react-datepicker__header": {
                backgroundColor: 'transparent',
                border: 'none',
                "& > .react-datepicker__current-month": {
                    fontSize: '1.125rem',
                    color: theme.palette.baseColors.darkgrey,
                },
            },
            "& > .react-datepicker__month > .react-datepicker__week > .react-datepicker__day": {
                border: `0.5px solid ${theme.palette.baseColors.darkbrown}`,
                backgroundColor: theme.palette.baseColors.lightgrey,
                borderRadius: '0.3rem',
                "&.react-datepicker__day--disabled": {
                    border: 'none',
                    backgroundColor: 'transparent',
                },
                "&.react-datepicker__day--selected": {
                    backgroundColor: theme.palette.baseColors.yellow,
                    color: theme.palette.baseColors.black,
                },
            },
        },
    },
  },
}));


const BoardSelection = ({
  BOARDS,
  styles,
  comments,
  date,
  pickup,
  setDate,
  setComments,
  setPickup,
  PICKUP_OPTS,
}) => {
  const classes = useStyles();
  const CalContainer = ({ children }) => {
    return (
      <div style={{ background: theme.palette.baseColors.white, }}>
        <CalendarContainer className={classes.calendarRoot}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <>
    <Grid container item flexDirection='column' justifyContent='center' alignItems='center' className={styles.formPageContainer}>
        <FormLabel>Select a date & pick up time</FormLabel>
        <Grid container flexDirection='row' justifyContent='center' alignItems='center' className={styles.formPageContainer}>
            <Grid item md={isMobile() ? 12 : 6} xs={12} className={styles.container}>
                <DatePicker calendarStartDay={1} calendarContainer={CalContainer} inline onChange={(date) => setDate(date)} selected={date} filterDate={isValidDate} />
            </Grid>
            <Grid item md={isMobile() ? 12 : 6} xs={12} flexDirection='column' justifyContent='center' alignItems='center' className={styles.container}>
                <LocationSelect date={date} group={PICKUP_OPTS} value={pickup} onChange={setPickup} />
            </Grid>
        </Grid>
        <FormLabel>Board Selection</FormLabel>
        <Typography variant='caption' style={{fontSize: '.825rem'}}>All boards include traditional Vietnamese cold cut meats, vegetables, pate, aioli, and bread.</Typography>
        <Typography variant='caption' style={{fontSize: '.825rem'}}>If you’re looking for a bigger serving size board (10+ Party), please reach out to us via email or instagram. Prices may vary.</Typography>
        <BoardSelect group={BOARDS} />
        <Divider />
        <TextField
            className={classes.textField}
            multiline
            value={comments}
            label='add comments'
            variant="outlined"
            onChange={(e) => setComments(e.target.value)}
            rows={3}
        />
    </Grid>
    </>
  );
}

export default BoardSelection;