import { Grid, Typography } from '@mui/material';
import React from 'react';

const Tag = () => {
  return (
    <Grid container style={{ padding: '0'}} justifyContent='center'>
      <Typography variant='overline' style={{color: 'white', fontSize: '8px'}}>Powered by Andrew Lim Consulting</Typography>
    </Grid>
  );
}

export default Tag;