import React, { useMemo } from 'react';
import {
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import theme from '../utils/theme';
import { calculateTotal, formatDay, spaceOrNewLine } from '../utils/helpers';

const Confirmation = ({
  classes,
  form,
  name,
  email,
  orderNumber,
  date,
  PICKUP_OPTS,
  pickup,
  comments,
  BOARDS,
  boardAQuantity,
  boardBQuantity,
  boardCQuantity,
}) => {
  const separator = useMemo(() => spaceOrNewLine(), []);
  return (
    <Grid ref={form} container item flexDirection='column' justifyContent='center' alignItems='flex-start' className={classes.formPageContainer}>
        <Typography variant='h4' className={classes.confirmation}>
            Thank you for your order, {name.split(' ')[0]}!
        </Typography>
        <Typography variant='h5' className={classes.confirmation}>ORDER #{orderNumber}</Typography>
        <Typography sx={{padding: '10px', textAlign: 'start',}}>An email has been sent to {email} with the order details.</Typography>
        <Typography sx={{padding: '10px', textAlign: 'start',}}>⚠️ PLEASE READ ⚠️</Typography>
        <Typography sx={{padding: '10px', textAlign: 'start',}}>To complete this order, submit your payment via venmo/zelle with your order number within 24 hours after order submission. Pickup location will be sent via email after payment is received.
          <Typography sx={{fontWeight: 'bold'}}>Venmo - (@banhmibar)</Typography>
          <Typography sx={{fontWeight: 'bold'}}>Zelle - (kelly_tnguyen@yahoo.com)</Typography>
        </Typography>
        <Typography sx={{padding: '10px', textAlign: 'start',}}>For any questions please contact us via email or instagram.</Typography>
        <Grid container alignItems='center' flexDirection='column' sx={{padding: '10px'}}>
          <Grid container alignItems='center' flexDirection='column' sx={{backgroundColor: theme.palette.baseColors.yellow}}>
            <Typography sx={{padding: '10px', fontWeight: 'bold'}} variant='h6'>Order Summary</Typography>
          </Grid>
          <Divider style={{ width: '100%', borderColor: theme.palette.baseColors.yellow }} />
          <Typography sx={{padding: '20px 10px 10px'}} variant='h7'>
          <Typography sx={{fontWeight: 'bold'}} component={`span`} variant='h7'>Scheduled Pick Up:</Typography>
            {separator}{`${date.toLocaleString('en-us', {weekday:'long'})}, ${formatDay(date.toLocaleString('en-us', {month:'long', day: '2-digit'}))} @ ${PICKUP_OPTS.items[pickup].location}
            `}
          </Typography>
        </Grid>
        <Divider
              className={classes.divider}
              style={{ width: '100%', padding: theme.palette.spacing.sm, borderColor: theme.palette.baseColors.lightgrey }}
        />
        <Grid container flexDirection='column' alignItems='flex-start' sx={{padding: '10px'}}>
            <Grid container flexDirection='row'>
                <Grid item xs={5}>
                    <Typography className={classes.text} variant='h7' sx={{fontWeight: 'bold', textAlign: 'start'}}>Items</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.text} variant='h7' sx={{fontWeight: 'bold', textAlign: 'center'}}>Quantity</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.text} variant='h7' sx={{fontWeight: 'bold', textAlign: 'center'}}>Amount</Typography>
                </Grid>
            </Grid>
            {BOARDS.items.filter(board => board.value !== 0).map(board => (
                <Grid key={`board-summary-${board.label}`} container flexDirection='row' sx={{padding: `${theme.palette.spacing.sm} 0`}}>
                    <Grid item xs={5}>
                        <Typography className={classes.text} variant='h7' sx={{textAlign: 'start'}}>{`${board.label} ($${board.price})`}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.text} variant='h7' sx={{textAlign: 'center'}}>{board.value}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.text} variant='h7' sx={{textAlign: 'center'}}>${board.value * board.price}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Divider
              className={classes.divider}
              style={{ width: '100%', padding: theme.palette.spacing.sm, borderColor: theme.palette.baseColors.darkgrey }}
            />
            <Grid container flexDirection='row' sx={{padding: `${theme.palette.spacing.sm} 0`}}>
                <Grid item xs={5}>
                    <Typography className={classes.text} variant='h7' sx={{fontWeight: 'bold', textAlign: 'start'}}>Total Amount</Typography>
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={3}>
                    <Typography className={classes.text} variant='h7' sx={{fontWeight: 'bold', textAlign: 'center'}}>${calculateTotal({boardAQuantity, boardBQuantity, boardCQuantity})}</Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Confirmation;