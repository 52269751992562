import React, { useEffect, useMemo, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {
    Alert,
    AlertTitle,
    Grid,
    Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormProgress from './FormProgress';
import { formatPhoneNumber, getNextValidDate, isMobile, isValidEmail, isValidPhoneNumber, } from '../utils/helpers';
import { MAP_DAY_TO_PICKUP, PICKUP_OPTS } from '../utils/const';
import { order } from './firebase/order';
import theme from '../utils/theme';
import small_boxed from '../assets/small_boxed.jpg';
import med_boxed from '../assets/med_boxed.jpg';
import large_boxed from '../assets/large_boxed.jpg';
import BoardSelection from './BoardSelection';
import ContactInfo from './ContactInfo';
import Details from './Details';
import Confirmation from './Confirmation';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
    },
    paper: {
        width: '80%',
        minWidth: isMobile() ? 'auto' : '400px',
        maxWidth: '900px',
        backgroundColor: theme.palette.baseColors.white,
        flex: '1 1 auto',
        [theme.breakpoints.up("sm")]: {
            margin: theme.palette.spacing.xl,
            padding: theme.palette.spacing.xl,
        },
        [theme.breakpoints.down("sm")]: {
            margin: theme.palette.spacing.mobile.xl,
            padding: theme.palette.spacing.mobile.xl,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '40px !important',
    },
    formPageContainer: {
        [theme.breakpoints.up("sm")]: {
            padding: theme.palette.spacing.lg,
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.palette.spacing.mobile.lg,
        },
    },
    container: {
        [theme.breakpoints.up("sm")]: {
            padding: theme.palette.spacing.md,
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.palette.spacing.mobile.md,
        },
    },
    confirmation: {
        fontWeight: 'bold !important',
        color: theme.palette.baseColors.brown,
        padding: theme.palette.spacing.sm,
    },
}));

const Form = () => {
  const classes = useStyles();
  const form = useRef();
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [boardAQuantity, setBoardAQuantity] = useState(0);
  const [boardBQuantity, setBoardBQuantity] = useState(0);
  const [boardCQuantity, setBoardCQuantity] = useState(0);
  const [date, setDate] = useState(getNextValidDate());
  const [pickup, setPickup] = useState(MAP_DAY_TO_PICKUP[date.getDay()]);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('');
  const [orderNumber, setOrderNumber] = useState(0);

  // Board options
  const BOARDS = useMemo(() => ({
    label: 'Select your board(s)',
    items: [
    {
        pic: small_boxed,
        height: 75,
        width: 105,
        style: {padding : '0 20px'},
        priceLabel: 'Price',
        qtyLabel: 'Quantity',
        label: '"Wittle" Board',
        desc: 'serving size 1-2 | box size 9" x 9"',
        price: 27,
        value: boardAQuantity,
        onChange: setBoardAQuantity,
    },
    {
        pic: med_boxed,
        height: 75,
        width: 80,
        style: {padding: '0 10px'},
        label: '"Just Right" Board',
        desc: 'serving size 3-6 | box size 12" x 8"',
        price: 45,
        value: boardBQuantity,
        onChange: setBoardBQuantity,
    },
    {
        pic: large_boxed,
        height: 75,
        width: 65,
        style: {paddingRight: '5px'},
        label: '"Issa Party" Board',
        desc: 'serving size 7-10 | box size 16" x 11"',
        price: 75,
        value: boardCQuantity,
        onChange: setBoardCQuantity,
    },
  ]}), [boardAQuantity, boardBQuantity, boardCQuantity]);

  // Text fields
  const TEXT_FIELDS = useMemo(() => ([
    {
        label: 'Full Name',
        value: name,
        onChange: setName,
    },
    {
        label: 'Phone Number',
        value: number,
        onChange: (value) => setNumber(formatPhoneNumber(value)),
    },
    {
        label: 'Email',
        value: email,
        onChange: setEmail,
    },
  ]), [name, number, email]);

  const handleNext = (orderNumber) => {
    if (orderNumber) { setOrderNumber(orderNumber); }
    let err = false;
    switch (activeStep) {
        case 0:
            if (boardAQuantity + boardBQuantity + boardCQuantity === 0) {
                setError('You must select at least one board.');
                err = true;
            }
            break;
        case 1:
            let missingName = false;
            let missingEmail = false;
            let missingNumber = false;
            if (name === '') missingName = true;
            if (email === '') missingEmail = true;
            if (number === '') missingNumber = true;
            if (missingName || missingEmail || missingNumber) {
                setError(`You must fill out the missing fields:${missingName ? '\n• Name' : ''}${missingNumber ? '\n• Phone Number' : ''}${missingEmail ? '\n• Email' : ''}`)
                err = true;
            } else if (!isValidPhoneNumber(number)) {
                setError(`You must fill out a valid phone number`)
                missingNumber = true
                err = true;
            } else if (!isValidEmail(email)) {
                setError(`You must fill out a valid email`)
                missingEmail = true
                err = true;
            }
            break;
    }

    if (!err) {
        setActiveStep((prevActiveStep) => prevActiveStep + (activeStep === 2 ? 2 : 1));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Change pickup options depending on date
  useEffect(() => {
    if (PICKUP_OPTS.items[pickup].isDisabled(date)) {
        setPickup(MAP_DAY_TO_PICKUP[date.getDay()]);
    }
  }, [date]);

  // Submit order
  const onSubmit = (e) => {
    e.preventDefault();
    const variables = {
        name,
        email,
        number,
        boardAQuantity,
        boardBQuantity,
        boardCQuantity,
        date: date.toLocaleDateString(),
        location: PICKUP_OPTS.items[pickup].label,
        comments,
    };
    order(variables, form.current.innerHTML, handleNext);
  };

  return (
    <>
    <Grid container flexDirection='column' className={classes.root}>
        <Paper className={classes.paper}>
            <FormProgress activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} onSubmit={onSubmit}>
                {activeStep === 0 && (
                    <BoardSelection 
                        BOARDS={BOARDS}
                        styles={classes}
                        comments={comments}
                        date={date}
                        pickup={pickup}
                        setDate={setDate}
                        setComments={setComments}
                        setPickup={setPickup}
                        PICKUP_OPTS={PICKUP_OPTS}
                    />
                )}
                {activeStep === 1 && (
                    <ContactInfo
                        error={error}
                        classes={classes}
                        TEXT_FIELDS={TEXT_FIELDS}
                    />
                )}
                {activeStep === 2 && (
                    <Details
                        form={form}
                        name={name}
                        number={number}
                        email={email}
                        date={date}
                        PICKUP_OPTS={PICKUP_OPTS}
                        pickup={pickup}
                        comments={comments}
                        BOARDS={BOARDS}
                        boardAQuantity={boardAQuantity}
                        boardBQuantity={boardBQuantity}
                        boardCQuantity={boardCQuantity}
                    />
                )}
                {activeStep === 4 && (
                    <Confirmation
                        classes={classes}
                        form={form}
                        name={name}
                        email={email}
                        orderNumber={orderNumber}
                        date={date}
                        PICKUP_OPTS={PICKUP_OPTS}
                        pickup={pickup}
                        comments={comments}
                        BOARDS={BOARDS}
                        boardAQuantity={boardAQuantity}
                        boardBQuantity={boardBQuantity}
                        boardCQuantity={boardCQuantity}
                    />
                )}
            </FormProgress>
        </Paper>
    </Grid>
    {error && 
        <Alert
            severity="error"
            onClose={() => setError('')}
            variant="filled"
            sx={{borderRadius: '18px', textAlign: 'left', whiteSpace: 'pre-wrap', position: 'fixed', bottom: [theme.palette.spacing.mobile.xl, theme.palette.spacing.xl], right: [theme.palette.spacing.mobile.xl, theme.palette.spacing.xl]}}
        >
            <AlertTitle sx={{textAlign: 'center'}}>Error</AlertTitle>
            {error}
        </Alert>}
    </>
  );
}

export default Form;