import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import theme from '../utils/theme';
import { isMobile } from '../utils/helpers';

const steps = [
  'Board Selection',
  'Contact Info',
  'Review Details',
  'Order Summary'
];

const FormProgress = ({ children, activeStep, handleNext, handleBack, onSubmit }) => {
  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step 
              key={label}
              {...stepProps} 
              sx={{
                paddingBottom: theme.palette.spacing.sm,
                '& .MuiStepConnector-root': {
                  top: '17.5px',
                  '& .MuiStepConnector-line': {
                    borderColor: theme.palette.baseColors.darkgrey,
                    borderTopWidth: '2px',
                  },
                },
                '& .MuiStepLabel-root': {
                  '& .MuiStepLabel-labelContainer': {
                    '& .MuiStepLabel-label': {
                      color: theme.palette.baseColors.brown,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              >
              <StepLabel {...labelProps}>{!isMobile() ? label : ''}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {children}
      {(activeStep !== steps.length) && (<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        {activeStep !== 0 && <Button
          variant='contained'
          color='secondary'
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>}
        <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === steps.length - 2 ?
            <Button
              disabled={submitClicked}
              variant='contained'
              color='secondary'
              onClick={
                (e) => {
                  setSubmitClicked(true);
                  onSubmit(e);
                }
              }
            >
              SUBMIT
            </Button>
            :
            <Button variant='contained' color='secondary' onClick={handleNext}>
              NEXT
            </Button>
          }
      </Box>)}
    </>
  );
}

export default FormProgress;