import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
    radioButton: {
        height: '62px',
        fontSize: '.8125rem !important',
        margin: '5px !important',
        display: 'flex !important',
        justifyContent: 'space-between !important',
        paddingLeft: "0 !important",
        width: '300px',
        borderRadius: '14px !important',
        backgroundColor: `${theme.palette.baseColors.white} !important`,
        textTransform: 'none !important',
    },
    radioButtonActive: {
        height: '62px',
        fontSize: '.8125rem !important',
        margin: '5px !important',
        display: 'flex !important',
        justifyContent: 'space-between !important',
        paddingLeft: "0 !important",
        width: '300px',
        borderRadius: '14px !important',
        backgroundColor: `${theme.palette.baseColors.lightgrey} !important`,
        textTransform: 'none !important',
    },
    radioDateButton: {
        backgroundColor: theme.palette.baseColors.lightgrey,
        height: '62px',
        borderRadius: '14px',
        minWidth: '40%',
        fontSize: '.625rem',
        alignContent: 'center',
        textTransform: 'uppercase',
    },
    radioDateButtonActive: {
        backgroundColor: `${theme.palette.baseColors.yellow}`,
        height: '62px',
        borderRadius: '14px',
        minWidth: '40%',
        fontSize: '.625rem',
        alignContent: 'center',
        textTransform: 'uppercase',
    },
}));

const LocationSelect = ({group, value, onChange, date}) => {
  const classes = useStyles();
  return (
    <FormControl>
        <RadioGroup
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {group.items.map(item => 
                !item.isDisabled(date) ? (
                    <div key={`form-item-${item.label}`} style={{flex: '1 1 auto'}}>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={value === item.value ? classes.radioButtonActive : classes.radioButton}
                        onClick={() => onChange(item.value)}
                    >
                        <div className={value === item.value ? classes.radioDateButtonActive : classes.radioDateButton}>
                            {`${date.toLocaleString('en-us', {weekday:'long'})}`}
                            <div style={{fontSize: '1rem'}}>{`${date.toLocaleString('en-us', {month:'2-digit', day: '2-digit'})}`}</div>
                        </div>
                        {item.location}
                    </Button>
                    <FormControlLabel 
                        value={item.value}
                        control={<Radio style={{display: 'none'}} color='secondary' disabled={item.isDisabled(date)} />}
                        label=""
                    />
                    </div>
                ) : null
            ).filter(i => !!i)}
        </RadioGroup>
    </FormControl>
  );
}

export default LocationSelect;