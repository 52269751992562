import * as React from 'react';
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

export default function NumberInputBasic({
  value,
  onChange,
}) {
  return (
    <NumberInput
      value={value}
      onChange={onChange}
      min={0}
    />
  );
}

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-size: .75rem;
  border-radius: 8px;
  color: black;
  background:'#fff';
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0px 2px 2px ${theme.palette.baseColors.lightgrey};
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;
  height: 35px;
  box-sizing: border-box;

  &.${numberInputClasses.focused} {
    border-color: ${theme.palette.baseColors.yellow};
  }

  &:hover {
    border-color: ${theme.palette.baseColors.yellow};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.baseColors.darkgrey};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 5px;
  outline: 0;
  width: 15px;
`,
);

const StyledButton = styled('button')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 13px;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  background: white;
  border: 0;
  color: ${theme.palette.baseColors.darkgrey};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.baseColors.lightgrey};
    border-color: ${theme.palette.baseColors.darkgrey};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
      background: ${theme.palette.baseColors.lightgrey};
      color: black;
    }

  border-color: ${theme.palette.baseColors.lightgrey};
  background: white;
  color: ${theme.palette.baseColors.darkgrey};
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    &:hover {
      cursor: pointer;
      background: ${theme.palette.baseColors.lightgrey};
      color: black;
    }

  border-color: ${theme.palette.baseColors.lightgrey};
  background: white;
  color: ${theme.palette.baseColors.darkgrey};
  }
  & .arrow {
    transform: translateY(-1px);
  }
`,
);
