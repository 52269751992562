import { Grid } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../utils/Logo';

const Header = () => {
  return (
    <Grid container style={{ padding: '0'}} justifyContent='center'>
      <a href="https://www.instagram.com/banhmibar.htx/" class="fa" style={{padding: '0px 10px', fontSize: '32px', color: '#DCAE3D', textDecoration: 'none'}}><img src="https://banhmibarhtx.com/instagram-50.png" style={{width:'32px', height:'32px'}} /></a>
      <a href="mailto:banhmibar.htx@gmail.com" class="fa" style={{padding: '0px 10px', fontSize: '32px', color: '#DCAE3D', textDecoration: 'none'}}><img src="https://banhmibarhtx.com/mail-50.png" style={{width:'32px', height:'32px'}} /></a>
      <a href="https://banhmibarhtx.com/" class="fa" style={{padding: '0px 10px', fontSize: '32px', color: '#DCAE3D', textDecoration: 'none'}}><img src="https://banhmibarhtx.com/globe-50.png" style={{width:'32px', height:'32px'}} /></a>
    </Grid>
  );
}

export default Header;