import { Grid } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../utils/Logo';

const Header = () => {
  return (
    <Grid container style={{ padding: '10px 0'}} justifyContent='center'>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Logo />
      </Link>
    </Grid>
  );
}

export default Header;