import { Button, Grid } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <Grid container justifyContent='center' alignItems='center' style={{ flex: '1 1 auto' }}>
      <Link to="/order">
        <Button variant='contained' color='secondary'>
          ORDER HERE
        </Button>
      </Link>
    </Grid>
  );
}

export default LandingPage;
