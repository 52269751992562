import { createTheme } from '@mui/material/styles';

export const baseColors = {
  background: '#FFF7EC',
  black: '#000',
  border: '#2D0B0B',
  brown: '#725427',
  darkbrown: '#4D4636',
  darkgrey: '#707070',
  gray: '#e0e0e0',
  lightgrey: '#EDECEA',
  red: '#FF0000',
  tan: '#DDC1A7',
  white: '#fff',
  yellow: '#DCAE3D',
};

const spacing = {
  mobile: {
    xl: '15px',
    lg: '12px 0',
    md: '10px',
    sm: '10px',
    xs: '5px',
  },
  xl: '40px',
  lg: '30px 20px',
  md: '20px',
  sm: '10px',
  xs: '5px',
}

const theme = createTheme({
  fonts: {
    base: "Segoe UI",
  },
  palette: {
    spacing,
    baseColors,
    text: {
      primary: baseColors.black,
    },
    primary: {
      main: baseColors.white,
      contrastText: baseColors.black,
    },
    primaryBackground: {
      backgroundColor: baseColors.white,
      color: baseColors.black,
    },
    secondary: {
      main: baseColors.yellow,
      contrastText: baseColors.black,
    },
    secondaryBackground: {
      backgroundColor: baseColors.yellow,
      color: baseColors.black,
    },
    warning: {
      main: baseColors.red,
      contrastText: baseColors.red,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          letterSpacing: '1px',
          borderRadius: '18px',
          fontWeight: 'bold',
          fontSize: '1.125rem',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: baseColors.tan,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          fontWeight: 'bold',
          color: baseColors.brown,
          '&.Mui-focused': {
            color: baseColors.yellow,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& > .MuiOutlinedInput-notchedOutline': {
              borderColor: baseColors.yellow,
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: baseColors.lightgrey,
          width: '35px',
          height: '35px',
          '& .MuiStepIcon-text': {
            fill: 'none',
          },
          '&.Mui-active': {
            color: baseColors.tan,
          },
          '&.Mui-completed': {
            color: baseColors.brown,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'SF Pro Text', '"Helvetica Neue"', 'sans-serif', 'Segoe UI'].join(', '),
  }
});

export default theme;