export const PICKUP_OPTS = {
    label: 'Select a date & pick up time',
    items: [
    {
        label: 'Friday 12 - 2pm (Heights)',
        location: '12 - 2pm (Heights)',
        value: 0,
        isDisabled: (date) => true,
    },
    {
        label: 'Friday 2:30 – 4:00pm (Sugarland)',
        location: '2:30 – 4:00pm (Sugarland)',
        value: 1,
        isDisabled: (date) => true,
    },
    {
        label: 'Friday 4:30 – 6pm (Katy)',
        location: '4:30 – 6pm (Katy)',
        value: 2,
        isDisabled: (date) => true,
    },
    {
        label: 'Saturday 1PM - 2PM (Spring Branch)',
        location: '1PM - 2PM (Spring Branch)',
        value: 3,
        isDisabled: (date) => date.getDay() !== 6,
    },
    {
        label: 'Saturday 2PM - 3PM (Spring Branch)',
        location: '2PM - 3PM (Spring Branch)',
        value: 4,
        isDisabled: (date) => date.getDay() !== 6,
    },
    {
        label: 'Saturday 3PM - 4PM (Spring Branch)',
        location: '3PM - 4PM (Spring Branch)',
        value: 5,
        isDisabled: (date) => date.getDay() !== 6,
    },
    {
        label: 'Sunday 1PM - 2PM (Spring Branch)',
        location: '1PM - 2PM (Spring Branch)',
        value: 6,
        isDisabled: (date) => date.getDay() !== 0,
    },
    {
        label: 'Sunday 2PM - 3PM (Spring Branch)',
        location: '2PM - 3PM (Spring Branch)',
        value: 7,
        isDisabled: (date) => date.getDay() !== 0,
    },
    {
        label: 'Sunday 3PM - 4PM (Spring Branch)',
        location: '3PM - 4PM (Spring Branch)',
        value: 8,
        isDisabled: (date) => date.getDay() !== 0,
    },
]};

export const MAP_DAY_TO_PICKUP = {
  0: 6,
  6: 3,
//   5: 0,
}