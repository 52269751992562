import { getDatabase, ref, child, push, increment, onValue, update } from "firebase/database";
import emailjs from '@emailjs/browser';

export const order = (orderData, orderHTML, handleNext) => {
  const db = getDatabase();

  // Get a key for a new Order.
  const newOrderKey = push(child(ref(db), 'orders')).key;

  // Write the new post's data simultaneously in the posts list and the user's post list.
  const updates = {};
  updates['/orders/count'] = increment(1);
  updates['/orders/' + newOrderKey] = orderData;
  updates['/user-orders/' + orderData.number + '/' + newOrderKey] = orderData;

  return update(ref(db), updates).then(() => {
    console.log('Order saved!');
    getOrderNumber(orderData, orderHTML, handleNext);
  })
  .catch((error) => {
    console.log(error);
  });;
}

export const getOrderNumber = (orderData, orderHTML, handleNext) => {
  // Get a database reference to our posts
  const db = getDatabase();
  const countRef = ref(db, '/orders/count');

  // Attach an asynchronous callback to read the data at our posts reference
  onValue(countRef, (snapshot) => {
    emailjs
      .send('service_gnodloe', 'template_w8pqjr2', {my_html: orderHTML}, {
        publicKey: 's-En2KPBBsTJd1dgm',
      })
      .then(
        () => {
          console.log('ORDER RECEIVED SUCCESS!');
          emailjs
            .send('service_gnodloe', 'template_j5ehj68', {...orderData, order_number: snapshot.val()}, {
                publicKey: 's-En2KPBBsTJd1dgm',
            })
            .then(
                () => {
                console.log('ORDER CONFIRMED SUCCESS!');
                handleNext(snapshot.val());
                },
                (error) => {
                console.log('FAILED...', error.text);
                },
            );
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }, (errorObject) => {
    console.log('The read failed: ' + errorObject.name);
  }); 
}