import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import LandingPage from './LandingPage';
import Form from './Form';
import '../css/App.css';
import Header from "./Header";
import Footer from "./Footer";
import Tag from "./Tag";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  // ...
  // The value of `databaseURL` depends on the location of the database
  databaseURL: "https://banhmibar-bd9fb-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Footer />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/order" element={<Form />} />
        </Routes>
        <Tag />
      </div>
    </Router>
  );
}

export default App;
